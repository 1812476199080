<script>
const formValues = () => ({
  proyecto_id: '',
  plantilla_id: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})
export default {
  name: 'ContratosProyectoView',
  data () {
    return {
      proyecto: null,
      departamentos: [],
      fields: [
        { key: 'num_catastral', label: 'Número catastral' },
        { key: 'cuenta_predial', label: 'Cuenta predial' },
        { key: 'inversionista.identificacion', label: 'Inversionista' },
        { key: 'inicio_contrato', label: 'Inicio contrato' },
        { key: 'termino_contrato', label: 'Fin contrato' },
        { key: 'contrato', label: 'Contrato' },
        { key: 'acciones', label: 'Acciones' }
      ],
      modal: false,
      loading: false,
      settingPlantilla: false,
      submitForm: false,
      formValues: formValues(),
      response: response()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      // Obtener proyecto
      this.initProyecto()
      this.initDepartamentos()
    },
    async initProyecto () {
      // Obtenemos información del proyecto
      const response = await this.$store.dispatch('condominioModule/getResourceToEdit',
        this.$route.params.proyecto)
      this.proyecto = response.data
    },
    async initDepartamentos () {
      // Obtenemos departamentos y contratos asignados
      this.loading = true
      const response = await this.$store.dispatch('contratosModule/getContratos', {
        proyecto_id: this.$route.params.proyecto
      })
      this.departamentos = response.data
      this.loading = false
    },
    async submitSelectPlantillaForm () {
      this.settingPlantilla = true
      this.response = response()
      this.formValues.proyecto_id = this.$route.params.proyecto
      const { error, message, data } = await this.$store.dispatch(
        'contratosModule/setPlantillaProyecto', this.formValues
      )
      this.settingPlantilla = false
      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
      this.init()
    },
    resetSelectPlantillaForm () {
      document.activeElement.blur()
      this.$formulate.reset('selectPlantillaForm')
      this.formValues = formValues()
      this.response = response()
      this.modal = false
    },
    onShowModal () {
      this.modal = true
    },
    async onGenerarContratos () {
      this.submitForm = true
      this.response = response()
      this.formValues.proyecto_id = this.$route.params.proyecto
      const result = await this.$store.dispatch(
        'contratosModule/generarContratosProyecto', this.formValues
      )
      this.submitForm = false
      await this.$onDownload(result, 'contratos.zip')
      this.init()
    }
  }
}
</script>
<template>
  <base-view title="Contratos" icon="file">
    <div>
      <b-modal
        v-model="modal"
        centered
        size="lg"
        scrollable
        dialog-class="x-modal-md"
      >
        <template #modal-title>Seleccionar plantilla de contratos</template>
        <template #default>
          <b-overlay :show="settingPlantilla">
            <template #overlay>
              <div class="text-center">
                <loading message="Asignando plantilla de contrato al departamento..."></loading>
              </div>
            </template>
            <FormulateForm
              ref="selectPlantillaForm"
              name="selectPlantillaForm"
              v-model="formValues"
              :errors="response.errors"
              @submit="submitSelectPlantillaForm"
            >
              <div class="card">
                <div class="card-header">
                  Seleccione la plantilla a asignar
                </div>
                <div class="card-body">
                  <b-row>
                    <b-col cols="12" md="12">
                      <FormulateInput
                        name="plantilla_id"
                        validation="bail|required">
                        <template #element>
                          <x-form-group title="Plantilla de contrato" required>
                            <x-select
                              v-model="formValues.plantilla_id"
                              dispatchPath="plantillasContratosModule/getResource"
                              getterListPath="plantillasContratosModule/getList"
                              :formatListWith="['id', 'identificacion']"
                            >
                            </x-select>
                          </x-form-group>
                        </template>
                      </FormulateInput>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </FormulateForm>
          </b-overlay>
        </template>
        <template #modal-footer>
          <!-- Enviar para crear o actualizar -->
          <div class="w-100">
            <x-form-footer-buttons
              :disabled="$refs.selectPeriodoForm ? $refs.selectPlantillaForm.hasErrors : false"
              :isEditing="true"
              :isCreating="false"
              @on-cancel="resetSelectPlantillaForm"
              @on-create="submitSelectPlantillaForm"
              @on-update="submitSelectPlantillaForm"
            ></x-form-footer-buttons>
          </div>
        </template>
      </b-modal>
      <check-authorization :requiresAuthorizations="['indice contratos']">
        <b-card no-body>
          <b-card-header>
            <div class="d-flex justify-content-between align-items-center">
              <span><b>Proyecto:</b> {{ proyecto ? proyecto.nombre : ''}}</span>
              <div>
                <b-button variant="primary" size="sm" @click="onShowModal">
                  Seleccionar plantilla
                </b-button>
                <b-button variant="secondary" size="sm" class="ml-2" @click="onGenerarContratos">
                  Generar contratos
                </b-button>
              </div>
            </div>
          </b-card-header>
          <b-card-body>
            {{ /* Listar departamentos y contratos */ }}
            <b-overlay :show="loading">
              <template #overlay>
                <div class="text-center">
                  <loading message="Obteniendo contratos, por favor espere..."></loading>
                </div>
              </template>
              <b-table
                id="contratosTable"
                :fields="fields"
                :items="departamentos"
                striped
                bordered
                responsive
                hover
                stacked="lg"
                show-empty
                small
              >
                <template #cell(contrato)="row">
                  <div v-if="row.value.plantilla">
                    {{ row.value.plantilla.identificacion }}
                  </div>
                  <div v-else>
                    Sin asignar
                  </div>
                </template>
                <template #cell(acciones)="row">
                  <b-link :to="{ name: 'contratos-proyecto-departamento', params: { proyecto: proyecto.id, departamento: row.item.num_catastral }}"><i data-feather="edit"></i></b-link>
                </template>
              </b-table>
            </b-overlay>
          </b-card-body>
        </b-card>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
